import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import {CircularProgress ,FormControlLabel,FormControl,Radio ,FormLabel,RadioGroup, Box, Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete,MenuItem, Select} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import B from '../images/B.png';
import B2 from '../images/B2.png';
import H from '../images/H.png';
import hand from '../images/hand.png';
import laptop from '../images/laptop.png';
import lock from '../images/lock.png';
import T from '../images/T.png';
import techni from '../images/techni.png';
import wallet from '../images/wallet.png';
import Lottie from 'lottie-react';
import appau from '../images/lottie/claping.json'
import maintE from '../images/lottie/maintE.json'

const loadingImages = [
     B,
     T,
     techni,
     laptop,
     lock,
     hand,
     B2,     
     wallet
    // Ajoutez autant d'images que nécessaire
  ];

  var BACKEND_URL = 'https://polygonBac.bt-support.net/api/';


export default function EquipementIntervention() {

    const [listEquipement, setListEquipement] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [listEquipementMaintenue, setListEquipementMaintenue] = useState([]);
    const [listEquipementNonMaintenue, setListEquipementNonMaintenue] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [equipement, setEquipement] = useState([]);
    const [equipementPiece, setEquipementPiece] = useState([]);
    const [idEquipements, setIdEquipements] =  useState([])
    const [nomEquipement, setNomEquipement] =  useState([])
    const [etatAvantEquipement, setEtatAvantEquipement] =  useState([])
    const [etatApresEquipement, setEtatApresEquipement] =  useState([])
    const [commentaireEquipement, setCommentaireEquipement] =  useState([])
    const [piecesEquipement, setPiecesEquipement] =  useState([]) 
    const [serieEquipement, setSerieEquipement] =  useState([]) 
    const [listPiece, setListPiece] =  useState([])   
    const [tckIntervention, settckIntervention] = useState([]);    
    const [openDialog, setOpenDialog] = React.useState(false);
    const { compte_id } = useParams();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    const [selectedValues, setSelectedValues] = useState([]);


    const date1=  new Date();
    const dateActuelle = date1.toISOString().split('T')[0];

    const handleChange = (event) => {
    }

    const { agence_id, interv_id } = useParams();

    //const navigate = useNavigate();
  const handleLinkClick = (link) => {
    // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
    // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
    navigate(`/${link}`)
};

    const ajouterValeur = () => {
      //setIdEquipements(prevArray => [...prevArray, nouvelleValeur]);
      console.log(idEquipements)
    };

    const request = async () => {
    try {

      const response1 = await axios.get(`${BACKEND_URL}agence/${agence_id}/equipements/${compte_id}`);
      const data1 = response1.data;
      
      setEquipement(data1);
      
      console.log(data1);
      
      // Deuxième requête
      const response2 = await axios.get(`${BACKEND_URL}equipement/equipementMaintenue/${interv_id}/${compte_id}`);
      const data2 = response2.data;
      
      setListEquipementMaintenue(data2);
      console.log(data2);
      
      // Filtrage
      const equipementsNonMaintenus = data1.filter(equipement => (
        !data2.some(maintenu => maintenu.numeroSerie === equipement.numeroSerie)
      ));
      console.log(equipementsNonMaintenus);
      setListEquipementNonMaintenue(equipementsNonMaintenus);

      const ids = equipementsNonMaintenus.map((equipement) => equipement.id);
      const nomEquipement = equipementsNonMaintenus.map((equipement) => equipement.id);
      const etatAvant = equipementsNonMaintenus.map((equipement) => equipement.etatAvant);
      const etatApres = equipementsNonMaintenus.map((equipement) => equipement.etatApres);
      const commentaire = equipementsNonMaintenus.map((equipement) => null);
      const pieces = equipementsNonMaintenus.map((equipement) => equipement.pieces);
      const piecesChanger = equipementsNonMaintenus.map((equipement) => equipement.piecesChanger);  
      const serie = equipementsNonMaintenus.map((equipement) => equipement.numeroSerie);
      
      setIdEquipements(ids);
      setNomEquipement(nomEquipement);
      setEtatAvantEquipement(etatAvant);
      setEtatApresEquipement(etatApres);
      setCommentaireEquipement(commentaire);
      setPiecesEquipement(pieces);
      setSerieEquipement(serie);
    

        await axios.get(`${BACKEND_URL}produit/showPiece/${compte_id}`)
        .then(({data}) => {           
            setEquipementPiece(data);
        })
        
        await axios.get(`${BACKEND_URL}equipement/equipementMaintenue/${interv_id}/${compte_id}`)
        .then(({data}) => {           
            setListEquipementMaintenue(data);
        })

    } catch (error) {
        setBackendErrorMessage(error.response.data || 'Une erreur s\'est produite lors de la récupération des données.');
    }
}

const modifierEtatAvant = (index, nouvelleValeur) => {
  setEtatAvantEquipement(prevIds => {
    const newIds = [...prevIds];
    newIds[index] = nouvelleValeur;
    return newIds;
  });
};

const modifierCommentaire = (index, nouvelleValeur) => {

  setCommentaireEquipement(prevIds => {
    const newIds = [...prevIds];
    newIds[index] = nouvelleValeur;
    return newIds;
  });
};

const modifierEtatApres = (index, nouvelleValeur) => {

  setEtatApresEquipement(prevIds => {
    const newIds = [...prevIds];
    newIds[index] = nouvelleValeur;
    return newIds;
  });
};

const modifierPieces = (index, nouvelleValeur) => {

  setPiecesEquipement(prevIds => {
    const newIds = [...prevIds];
    newIds[index] = nouvelleValeur;
    return newIds;
  });

  console.log(piecesEquipement)

};


const modifierEquipement = async () => {
  console.log(idEquipements.length)
  for (let i = 0; i < idEquipements.length; i++) {
      try {
        await axios.put(`${BACKEND_URL}equipement/update/${idEquipements[i]}/${compte_id}`, {
          etatAvant: etatAvantEquipement[i],
          etatApres: etatApresEquipement[i],
          commentaire: commentaireEquipement[i],
          piecesChanger: piecesEquipement[i]
      
        }).then((data) => {
          console.log(`Equipement avec l'ID ${idEquipements[i]}  avec succès`);
          console.log(data);

          console.log(commentaireEquipement[i])

          if(commentaireEquipement[i] != null && commentaireEquipement[i] != '' )
          {
            console.log('oui')
            try{
             axios.post(`${BACKEND_URL}equipement/create-equipementMaintenue/${interv_id}/${compte_id}`, {
                interv_id : interv_id,
                equipement_id: nomEquipement[i] ,
                etatAvant: etatAvantEquipement[i],
                etatApres: etatApresEquipement[i],
                commentaire: commentaireEquipement[i],
                piecesChanger: `${piecesEquipement[i]}`,
                numeroSerie: serieEquipement[i],
              })
            } catch (error) {
              console.error(error.response.data)
            }
          }
          else{
            console.log('non')            
          }
          
          // Ajoutez ici la logique supplémentaire après la suppression
          // setOpen(true);
        }) ;
      } catch (error) {
        console.error('Erreur lors de la modification de l\'equipement', error);
        // Ajoutez ici la gestion des erreurs
      }

  }
  request()
setLoading(false)
  
}

   
    const Deleterequest = async (equip_id) => {
      if (!window.confirm('Voulez-vous vraiment supprimer?')) {
        return;
      }    
        try {
          await axios.delete(`${BACKEND_URL}equipement/equipementMaintenue/destroy/${equip_id}/${compte_id}`);          
          // Ajoutez ici la logique supplémentaire après la suppression
          setOpen(true);
          request()
          window.alert('Element supprimer avec succès')
          console.log(`Equipements maintenue avec l'ID ${equip_id} supprimé avec succès`);
        } catch (error) {
          console.error('Erreur lors de la suppression de l\'equipement', error);
          window.alert(error.response.data.error)
          //setBackendErrorMessage('')
          // Ajoutez ici la gestion des erreurs
        }
    }

                

        useEffect(() => {
          try {
            request();
            
          } catch (error) {
            console.log(error)
          }            
            const timer = setTimeout(() => {
                // Mettez à jour l'état pour arrêter le chargement après 3 secondes
                setLoading(false);
              }, 6000); // 3000 millisecondes = 3 secondes
          
              // Nettoyez le timer lorsque le composant est démonté
              const interval = setInterval(() => {
                setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
              }, 600);              

              ajouterValeur();
              //console.log(idEquipements)

              const handleResize = () => {
                setWindowWidth(window.innerWidth);
            };
    
            window.addEventListener('resize', handleResize);
  

              return () => {
                clearTimeout(timer);
                clearInterval(interval)
                window.removeEventListener('resize', handleResize);
            };
          }, []);

      const [loading, setLoading] = useState(true);
      const [currentImage, setCurrentImage] = useState(0);

      const handleSelectChange = (event, index) => {
        const {
          target: { value },
        } = event;

        const selectedValues = typeof value === 'string' ? value.split(',') : value;

        setListPiece(
         selectedValues
        );

        setPiecesEquipement(prevIds => {
          const newIds = [...prevIds];
          newIds[index] = selectedValues;
          return newIds;
        });

        console.log(selectedValues)
      
        console.log(piecesEquipement)

        console.log(listPiece)
      };


  const navigate = useNavigate();

  if(!loading) {
    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            <Nav compte_id={compte_id} />
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>
               <Button variant="contained" startIcon={<ArrowBackIcon />} style={{marginRight: 'auto'}}   onClick={() => {handleLinkClick(`interventions/${compte_id}`)}} >
                    RETOUR                      
            </Button>
                                 
            </Stack>
            <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                      marginLeft: 2, 
                      marginRight: 2, 
                      borderRadius: 5, 
                      minWidth: windowWidth-150,
                      boxShadow: '0px 0px 5px #0D1282',
                      backgroundColor:'white'}}>
                  <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
                    Liste des équipements
                  </Stack>
                  <Stack>
                    {windowWidth>700?
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: windowWidth-200 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#0D1282',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={7}>Équipements en attente</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                     <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro Série</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Nom</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Garantie</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >État avant</TableCell> 
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Commentaire</TableCell> 
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >État apres</TableCell> 
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Pièces</TableCell>                                                                                                                                
                                    </TableRow>
                                        {
                                          listEquipementNonMaintenue.length === 0 ? (
                                            <TableRow>
                                              <TableCell colSpan={8} align="center" style={{fontWeight: 600,
                                                 color: 'red',
                                                 align:'center',
                                                 }}>
                                                  <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent:"center",
                                                    alignItems: "center"
                                                  }} >
                                                <Lottie animationData={appau}
                                                  loop
                                                  style={{
                                                    height:100,
                                                    width:200,
                                                    align:'center'
                                                  }} 
                                                  />
                                                  <div> Pas d'équipements en attentes.
                                                <br />
                                                🎉 Félicitations pour la bonne réalisation de cette intervention.
                                              </div>
                                              </div>
                                               </TableCell>
                                              
                                            </TableRow>
                                          ) : (
                                        listEquipementNonMaintenue.map((row, index) => (   
                                                                                 
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600}}>{row.numeroSerie}</TableCell>                                            
                                            <TableCell align="center" style={{fontWeight: 600}}>{row.nomProd}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>
                                                <Button style={{color:'white',
                                                  backgroundColor: dateActuelle>=row.dateDG && dateActuelle<=row.dateFG ? '#8FD14F':'#DD4A48',
                                                  borderRadius:15,
                                                  fontFamily:'monospace', padding:2, textTransform:'none'}}
                                                  onClick={(e)=>{
                                                    window.alert(`Debut:${row.dateDG}-- Fin:${row.dateFG}`)
                                                  }}>
                                                   {dateActuelle>=row.dateDG && dateActuelle<=row.dateFG ?'OUI':'NON'}
                                                   </Button>
                                            </TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}> 
                                                  <FormControl>                                                    
                                                    <RadioGroup
                                                      aria-labelledby="demo-radio-buttons-group-label"
                                                      defaultValue={etatAvantEquipement[index]}
                                                      name="radio-buttons-group"
                                                      onChange={(e) => modifierEtatAvant(index, e.target.value)}
                                                    >
                                                      <FormControlLabel value="En service" control={<Radio />} label="ES" />
                                                      <FormControlLabel value="Hors service" control={<Radio />} label="HS" />                                                      
                                                    </RadioGroup>
                                                  </FormControl>
                                            </TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>
                                               <TextField
                                               multiline
                                               minRows={2} 
                                               value={commentaireEquipement[index]}
                                               onChange={(e) => {
                                                modifierCommentaire(index, e.target.value)
                                                console.log(e.target.value)
                                              }     
                                              }                                       
                                               >
                                               </TextField>
                                              </TableCell> 
                                              <TableCell align="center" style={{fontWeight: 600}}>
                                                  <FormControl>                                                    
                                                        <RadioGroup
                                                          aria-labelledby="demo-radio-buttons-group-label"
                                                          name="radio-buttons-group"
                                                          onChange={(e) => modifierEtatApres(index, e.target.value)}
                                                        >
                                                          <FormControlLabel value="En service" control={<Radio />} label="ES" />
                                                          <FormControlLabel value="Hors service" control={<Radio />} label="HS" />                                                      
                                                        </RadioGroup>
                                                  </FormControl>                                            
                                            </TableCell>                                             
                                            <TableCell align="center" style={{fontWeight: 600}}>
                                            <Select
                                                style={{ minWidth: 150 }}
                                                multiple
                                                value={piecesEquipement[index] || []}
                                                onChange={(e) => {
                                                  handleSelectChange(e , index)
                                                  //modifierPieces(index, listPiece)

                                                }
                                                }
                                                
                                              >
                                                {equipementPiece &&  equipementPiece.map((row, index) => (
                                                  <MenuItem key={index} value={row.nomProd}>
                                                    {row.nomProd}
                                                  </MenuItem>
                                                ))}
                                              </Select>
                                            </TableCell>                                                                                                                                     
                                    </TableRow>
                                                      )))}
                                                </TableBody>
                            </Table>
                    </TableContainer>
                    :
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 300, maxWidth: 320 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#0D1282',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}}>Équipements en attente</TableCell>
                                    </TableRow>
                                </TableHead>                                
                                {
                                  listEquipementNonMaintenue.length === 0 ? (
                                    <TableRow>
                                      <TableCell  align="center" style={{fontWeight: 600, color: 'red'}}>
                                      <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent:"center",
                                                    alignItems: "center"
                                                  }} >
                                                <Lottie animationData={appau}
                                                  loop
                                                  style={{
                                                    height:100,
                                                    width:200,
                                                    align:'center'
                                                  }} 
                                                  />
                                        <div>
                                        Pas d'équipements en attentes.
                                        <br />
                                        🎉 Félicitations pour la bonne réalisation de cette intervention.
                                        </div>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  ) : (
                                listEquipementNonMaintenue.map((row, index) => (
                                
                                  <TableBody>   
                                     <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#5DEBD7'}} >Série et nom équipement</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                         <TableCell align="center" style={{fontWeight: 600}}>{row.numeroSerie} { row.nomProd}</TableCell>                                         
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Garantie</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                         <TableCell align="center" style={{fontWeight: 600}}>
                                         <Button style={{color:'white',
                                                  backgroundColor: dateActuelle>=row.dateDG && dateActuelle<=row.dateFG ? '#8FD14F':'#DD4A48',
                                                  borderRadius:15,
                                                  fontFamily:'monospace', padding:2, textTransform:'none'}}
                                                  onClick={(e)=>{
                                                    window.alert(`Debut:${row.dateDG}-- Fin:${row.dateFG}`)
                                                  }}>
                                                   {dateActuelle>=row.dateDG && dateActuelle<=row.dateFG ?'OUI':'NON'}
                                                   </Button>
                                          </TableCell>                                         
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >État avant</TableCell>                                             
                                    </TableRow>
                                    <TableRow>
                                          <TableCell align="center" style={{fontWeight: 600}}> 
                                                        <FormControl>                                                    
                                                          <RadioGroup
                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                            defaultValue={etatAvantEquipement[index]}
                                                            name="radio-buttons-group"
                                                            onChange={(e) => modifierEtatAvant(index, e.target.value)}
                                                          >
                                                            <FormControlLabel value="En service" control={<Radio />} label="ES" />
                                                            <FormControlLabel value="Hors service" control={<Radio />} label="HS" />                                                      
                                                          </RadioGroup>
                                                        </FormControl>
                                                  </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Commentaire</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" style={{fontWeight: 600}}>
                                               <TextField
                                               multiline
                                               minRows={2} 
                                               value={commentaireEquipement[index]}
                                               onChange={(e) => modifierCommentaire(index, e.target.value)}                                            
                                               >
                                               </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >État apres</TableCell>                                             
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600}}>
                                                  <FormControl>                                                    
                                                        <RadioGroup
                                                          aria-labelledby="demo-radio-buttons-group-label"
                                                          name="radio-buttons-group"
                                                          onChange={(e) => modifierEtatApres(index, e.target.value)}
                                                        >
                                                          <FormControlLabel value="En service" control={<Radio />} label="ES" />
                                                          <FormControlLabel value="Hors service" control={<Radio />} label="HS" />                                                      
                                                        </RadioGroup>
                                                  </FormControl>                                            
                                            </TableCell>
                                    </TableRow>
                                    <TableRow>
                                             <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Pièces</TableCell>                                                                                                                                                                    
                                    </TableRow>                                         
                                    <TableRow>                                             
                                            <TableCell align="center" style={{fontWeight: 600}}>
                                            <Select
                                                style={{ minWidth: 150 }}
                                                multiple
                                                value={piecesEquipement[index] || []}
                                                onChange={(e) => {
                                                  handleSelectChange(e , index)
                                                  //modifierPieces(index, listPiece)

                                                }
                                                }
                                                
                                              >
                                                {equipementPiece &&  equipementPiece.map((row, index) => (
                                                  <MenuItem key={index} value={row.nomProd}>
                                                    {row.nomProd}
                                                  </MenuItem>
                                                ))}
                                              </Select>
                                            </TableCell>                                                                                                                                     
                                    </TableRow>
                                    </TableBody>
                                  )))}
                                                
                            </Table>
                    </TableContainer>
                    }
                    
                  </Stack>
                  <Button variant="contained" style={{margin: 20}} startIcon={<AddIcon />}   onClick={() => {
                    setLoading(true)
                    modifierEquipement()
                  }} >
                        Enregistrer                      
                    </Button>
                    <Stack style={{marginBottom:20}}>
                      {windowWidth>700 ?
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth:windowWidth-200 , }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#0D1282',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={8}>Équipements maintenues</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                     <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro Série</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Nom</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Garantie</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >État avant</TableCell> 
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Commentaire</TableCell> 
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >État apres</TableCell> 
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Pièces</TableCell> 
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Action</TableCell>                                                                                      
                                    </TableRow>
                                        {
                                        
                                          listEquipementMaintenue.length === 0 ? (
                                            <TableRow>
                                              <TableCell colSpan={8} align="center" style={{fontWeight: 600, color: 'red',
                                                align:'center'
                                              }}>
                                              <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent:"center",
                                                            alignItems: "center"
                                                          }} >
                                                        <Lottie animationData={maintE}
                                                          loop
                                                          style={{
                                                            height:300,
                                                            width:400,
                                                            align:'center'
                                                          }} 
                                                          />
                                                <div>
                                                Aucun équipement maintenu.
                                                <br />
                                                🎉 Bonne maintenance et soyez attentif.
                                                </div>
                                                </div>
                                              </TableCell>
                                            </TableRow>
                                          ) : (
                                            listEquipementMaintenue.map((row, index) => (
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.numeroSerie}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.nomProd}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>
                                            <Button style={{color:'white',
                                                  backgroundColor: dateActuelle>=row.dateDG && dateActuelle<=row.dateFG ? '#8FD14F':'#DD4A48',
                                                  borderRadius:15,
                                                  fontFamily:'monospace', padding:2, textTransform:'none'}}
                                                  onClick={(e)=>{
                                                    window.alert(`Debut:${row.dateDG}-- Fin:${row.dateFG}`)
                                                  }}>
                                                   {dateActuelle>=row.dateDG && dateActuelle<=row.dateFG ?'OUI':'NON'}
                                                   </Button>
                                            </TableCell>
                                            <TableCell align="left" style={{fontWeight: 600 , color: row.etatAvant=='En service' ? 'green' : 'red'}}>{row.etatAvant}</TableCell> 
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.commentaire}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600 , color: row.etatApres=='En service' ? 'green' : 'red'}}>{row.etatApres}</TableCell> 
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.piecesChanger == 'null' ? 'RAS' : row.piecesChanger}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>
                                              <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                </Tooltip></TableCell>                                                                                      
                                    </TableRow>
                                    )))}
                                  </TableBody>
                            </Table>
                    </TableContainer>
                    :
                    <TableContainer>
                          <Table sx={{ minWidth: 300 ,maxWidth:320 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#0D1282',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={2}>Équipements maintenues</TableCell>
                                    </TableRow>
                                </TableHead>
                                {
                                listEquipementMaintenue.length === 0 ? (
                                  <TableRow>
                                    <TableCell colSpan={5} align="center" style={{fontWeight: 600, color: 'red',
                                      align:'center'
                                    }}>
                                    <div style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  justifyContent:"center",
                                                  alignItems: "center"
                                                }} >
                                              <Lottie animationData={maintE}
                                                loop
                                                style={{
                                                  height:200,
                                                  minWidth:270,
                                                  maxWidth:300,
                                                  align:'center'
                                                }} 
                                                />
                                      <div>
                                      Aucun équipement maintenu.
                                      <br />
                                      🎉 Bonne maintenance et soyez attentif.
                                      </div>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                ) : (
                                  listEquipementMaintenue.map((row, index) => (
                                <TableBody>
                                     <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#E3FEF7'}} colSpan={2}>Série et nom équipement</TableCell>
                                      </TableRow>
                                      <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: 'white'}} colSpan={2}>{row.numeroSerie} {row.nomProd}</TableCell>
                                      </TableRow>
                                      <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2}>Garantie</TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell align="center" style={{fontWeight: 600, backgroundColor: 'white'}} colSpan={2}>
                                            <Button style={{color:'white',
                                                  backgroundColor: dateActuelle>=row.dateDG && dateActuelle<=row.dateFG ? '#8FD14F':'#DD4A48',
                                                  borderRadius:15,
                                                  fontFamily:'monospace', padding:2, textTransform:'none'}}
                                                  onClick={(e)=>{
                                                    window.alert(`Debut:${row.dateDG}-- Fin:${row.dateFG}`)
                                                  }}>
                                                   {dateActuelle>=row.dateDG && dateActuelle<=row.dateFG ?'OUI':'NON'}
                                                   </Button>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >État avant</TableCell> 
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >État apres</TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell align="center" style={{fontWeight: 600 , backgroundColor: 'white', color: row.etatAvant=='En service' ? 'green' : 'red'}}>{row.etatAvant}</TableCell> 
                                        <TableCell align="center" style={{fontWeight: 600 , backgroundColor: 'white', color: row.etatApres=='En service' ? 'green' : 'red'}}>{row.etatApres}</TableCell>                                             
                                      </TableRow>
                                      <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Commentaire</TableCell>                                              
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Pièces</TableCell> 
                                      </TableRow>
                                      <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: 'white'}}>{row.commentaire}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: 'white'}}>{row.piecesChanger == 'null' ? 'RAS' : row.piecesChanger}</TableCell>                                            
                                      </TableRow>
                                      <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}  colSpan={2} >Action</TableCell>                                                                                      
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: 'white'}} colSpan={2}>
                                              <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                </Tooltip>
                                        </TableCell>                                                                                      
                                    </TableRow>
                                    </TableBody>
                                    )))}
                                  
                            </Table>
                    </TableContainer>
                     }
                    </Stack>                 
            </Stack>         
            
        </div>
    );
}
else
{
    return(
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // 100% de la hauteur de la vue
        }}
      >
        <Stack direction={'column'} alignItems={'center'} spacing={1}>
            <CircularProgress />
            <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
        </Stack>
      </Box>
    );
}
}