import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import {Box,CircularProgress,Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete, MenuItem} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SyncIcon from '@mui/icons-material/Sync';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { SwapHoriz  as TransferIcon } from '@mui/icons-material';
import axios from 'axios';
import Fuse from 'fuse.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import m2agroup from '../images/m2a.png';



var BACKEND_URL = 'https://polygonBac.bt-support.net/api/';
var countData=1;


export default function EquipementOfAgence() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [listProduit, setlistProduit] = useState([]);
    const [listEquipement, setListEquipement] = useState([]);
    const [agence, setagence] = useState([]);
    const [Client, setClient] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [nomEquipement, setnomEquipement] = useState('');
    const [produit_id, setproduit_id] = useState('');
    const [numeroSerie, setNumerpSerie] = useState('');
    const [statutEquipement, setstatutEquipement] = useState('');
    const [dateFG, setDateFG] = useState('');
    const [dateDG, setDateDG] = useState('');
    const date1=  new Date();
    const dateActuelle = date1.toISOString().split('T')[0];


    let [currentPage, setCurrentPage] =useState(1);    
    const dataPerPage = 10;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const data = listEquipement.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(listEquipement.length /dataPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    const maxPagesToShow = windowWidth>700?5:3;
    
    const getPageNumbersToShow = () => {
      const startPage = Math.max(1, currentPage - maxPagesToShow);
      const endPage = Math.min(nPage, currentPage + maxPagesToShow);
    
      return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
    };

    const { agence_id, client_id } = useParams();



    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogTransfert, setOpenDialogTransfert] = React.useState(false);
    const [openDialogGarantie, setOpenDialogGarantie] = React.useState(false);
    const [equipement_id, setEquipement_id] = useState('');
    const [agence_depart, setAgence_depart] = useState('');
    const [agence_arrive, setAgence_arrive] = useState('');
    const [objet_transfert, setObjet_transfert] = useState('');
    const [listAgence, setListAgence] = useState([]);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    const { compte_id } = useParams();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
      const action = (
        <React.Fragment>
          <Button color="secondary" size="small" onClick={handleClose}>
            UNDO
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
  

    const request = async () => {
      console.log(dateActuelle);
      try{
        await axios.get(`${BACKEND_URL}agence/show/${agence_id}/${compte_id}`)
        .then(({data}) => {
            setagence(data);
            console.log(agence);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}agence/${agence_id}/equipements/${compte_id}`)
        .then(({data}) => {
          setListEquipement(data);
            console.log(listEquipement);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}produit/showEquipement/${compte_id}`)
        .then(({data}) => {
          setlistProduit(data);
            console.log(listProduit);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}client/${client_id}/agences/${compte_id}`)
        .then(({data}) => {
          setListAgence(data);
            console.log(listAgence);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
        await axios.get(`${BACKEND_URL}client/show/${client_id}/${compte_id}`)
        .then(({data}) => {
          setClient(data);
            console.log(Client);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
      } catch (error) {
        console.log(error)
      }
    }

    const handleClickOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setBackendErrorMessage('')
      setOpenDialog(false);
    };

    const handleClickOpenDialogGarantie = (equipement_id,dateDG,dateFG) => {
      setOpenDialogGarantie(true);
      setEquipement_id(equipement_id);
      setDateDG(dateDG);
      setDateFG(dateFG);
    };
  
    const handleCloseDialogGarantie = () => {
      setBackendErrorMessage('')
      setEquipement_id(null);
      setDateDG('');
      setDateFG('');
      setOpenDialogGarantie(false);
    };

    const handleClickOpenDialogTransfert = (equipement_id, agence_depart) => {
      setOpenDialogTransfert(true);
      setEquipement_id(equipement_id);
      setAgence_depart(agence_depart);
    };
  
    const handleCloseDialogTransfert = () => {
      setBackendErrorMessage('')
      setOpenDialogTransfert(false);
    };
    
    const transfertEquipement = async (e) => {
      if(agence_arrive==null || objet_transfert=='')
      {
        setBackendErrorMessage('Veuillez remplir tous les champs')
      }
      else{
      await axios.post(`${BACKEND_URL}equipement/transfertEquipement/${equipement_id}/${compte_id}`, {
                        refIdAgenceApres:  agence_arrive,
                        objetTransfert: objet_transfert,
                }).then((data) =>{
                  request();
                  console.log(data)
                  setOpenDialogTransfert(false);
                  setBackendErrorMessage('')
                })
                .catch(error => {
                  console.error('Erreur de requête :', error);
                  setBackendErrorMessage(error.response.data.error)
      });
    }
    };

    const modifierGarantie = async (e) => {
      if(dateDG=='' || dateFG=='')
      {
        setBackendErrorMessage('Veuillez remplir tous les champs')
      }
      else{
      await axios.put(`${BACKEND_URL}equipement/modifierGarantie/${equipement_id}/${compte_id}`, {
                        dateDG:  dateDG,
                        dateFG: dateFG,
                }).then((data) =>{
                  request();
                  console.log(data)
                  setOpenDialogGarantie(false);
                  setDateDG('');
                  setDateFG('');
                  setBackendErrorMessage('');
                })
                .catch(error => {
                  console.error('Erreur de requête :', error);
                  setBackendErrorMessage(error.response.data.error)
      });
    }
    };

    const ajoutEquipement = async (e) => {
      if(produit_id==null || statutEquipement=='' || numeroSerie=='' || dateFG=='' || dateDG=='')
      {
        setBackendErrorMessage('Veuillez remplir tous les champs')
      }
      else 
      {
        await axios.post(`${BACKEND_URL}equipement/create/${agence_id}/${compte_id}`, {
                        //nomEquipement:  nomEquipement,
                        produit_id:  produit_id,
                        numeroSerie: numeroSerie,
                        statutEquipement: statutEquipement,
                        dateFG:  dateFG,
                        dateDG:  dateDG,
                }).then((data) =>{
                  request();
                  console.log(data)
                  setOpenDialog(false);
                  setBackendErrorMessage('')
                })
                .catch(error => {
                  console.error('Erreur de requête :', error);
                  setBackendErrorMessage(error.response.data.error)
      });

      }
      
    };

    const changerStatutEquipement = async (equipement_id , nom, statut) => {
      console.log(statut)
      if(statut=='En service')
      {
        console.log(statut)
         axios.put(`${BACKEND_URL}equipement/changerStatut/${equipement_id}/${compte_id}`, {
                        nomEquipement:  nom,
                        etatApres: 'Hors service',
              }).then((data) =>{
                request();
                console.log(data)
              })
              .catch(error => {
                console.error('Erreur de requête :', error);
                setBackendErrorMessage(error.response.data.error)
            });

      }
      else if (statut=='Hors service'){
         axios.put(`${BACKEND_URL}equipement/changerStatut/${equipement_id}/${compte_id}`, {
                            nomEquipement:  nom,
                            etatApres: 'En service',
                    }).then((data) =>{
                      request();
                      console.log(data)
                    })
                    .catch(error => {
                      console.error('Erreur de requête :', error);
                      setBackendErrorMessage(error.response.data.error)
                  });
      }
      
    };

    const Deleterequest = async (approId) => {
      if (!window.confirm('Voulez-vous vraiment supprimer?')) {
        return;
      }    
        try {
          await axios.delete(`${BACKEND_URL}equipement/destroy/${approId}/${compte_id}`);
          console.log(`Approvisionnement avec l'ID ${approId} supprimé avec succès`);
          // Ajoutez ici la logique supplémentaire après la suppression
          setOpen(true);
          request()
          window.alert('Element supprimer avec succès')
        } catch (error) {
          console.error('Erreur lors de la suppression de l\'approvisionnement', error);
          window.alert(error.response.data.error)
          // Ajoutez ici la gestion des erreurs
        }
    }

    const handleLinkClick = (link) => {
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
    };

            
    const [searchQuery, setSearchQuery] = useState('');
    // Configuration de Fuse.js
const fuseOptions = {
 keys: ['numeroSerie', 'nomEquipement'], // Les champs à rechercher
 includeScore: true,
};

// Initialiser un nouvel objet Fuse avec les options
const fuse = new Fuse(listEquipement, fuseOptions);

// Fonction pour gérer le changement de la recherche
const handleSearchChange = (e) => {
 const query = e.target.value;
 setSearchQuery(query);

 // Effectuer la recherche et mettre à jour les résultats
 const results = fuse.search(query);

 if (query==''){
   //setApproData(results.map((result) => result.item));
   request()
 }
 else{
   setListEquipement(results.map((result) => result.item));
 }

};
    
        const handlePrint = () => {
          const printWindow = window.open('', '_blank');
          
            const tableRows = listEquipement.map((row, index) => (
              `<tr>
                <td>${index + 1}</td>
                <td>${row.numeroSerie}</td>                
                <td>${row.nomProd}</td> 
                <td>${dateActuelle>=row.dateDG && dateActuelle<=row.dateFG?'OUI':'NON'}</td>
                <td>${row.dateDG}</td>
                <td>${row.dateFG}</td>
                <td>${row.statutEquipement}</td>                                      
              </tr>`
            )).join('');
            
            // Utilisez la chaîne HTML générée pour construire le document HTML
            const htmlContent = `
              <html lang="fr">
                <head>
                  <meta charset="UTF-8">
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title>Tableau des équipements de ${agence.nomAgence}</title>                
                  <style>
                    body { font-family: Arial, sans-serif; }
                    table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                    th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                    th { background-color: #f2f2f2; }
                    .header {
                      border-bottom: 2px solid #000;
                      margin-bottom: 20px;
                      padding-bottom: 10px;
                  }
                  .header img {
                      width: 200px;
                      height: auto;
                      float: left;
                      margin-right: 20px;
                  }
                  .header h1,
                  .header p {
                      margin: 0;
                      padding: 0;
                  }
                  /* Aligne les éléments pour l'impression */
                  .header h1 {
                      font-size: 24px;
                      line-height: 1.2;
                      color: red
                  }
                  .header p {
                      font-size: 14px;
                      line-height: 1.4;
                  }
                  </style>
                </head>
                <body>
                <div class="printable header">
                    <img src=${m2agroup} alt="Logo">                    
                    <h1>POLYGON BIO SERVICES SARL</h1>
                    <p>09 BP 969 Ouagadougou 09</p>
                    <p>Bureau     : (+226) 25 46 29 21</p>
                    <p>Portable 1 : (+226) 70 80 82 08</p>
                    <p>Portable 2 : (+226) 79 67 11 11</p>
                    <p>Whatsapp/ WeChat : (+226) 76 59 71 28</p>
                    <p>E-mail : polygonbioservice@yahoo.fr</p>
                    <p>www.polygonbioservices.com</p>
                    <p>Situé au quartier Sinyiri côté Ouest de la ZAD (SIAO)</p>
                    <p>Siège social 01 BP 6727 OUAGA 01</p>                    
                </div>
                  <h2>Tableau des équipements de ${agence.nomAgence}</h2>
                  <table>
                    <thead>
                      <tr>
                      <th>Numéro</th>
                      <th>Numéro série</th>
                      <th>Nom</th>
                      <th>Garantie</th>
                      <th>Date début garantie</th>
                      <th>Date fin garantie</th>
                      <th>Statut</th>                      
                       </tr>
                    </thead>
                    <tbody>
                      ${tableRows}
                    </tbody>
                  </table>
                </body>
              </html>`;
            
            // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
            printWindow.document.write(htmlContent);
            printWindow.document.close();
            printWindow.print();
        };

        const handleExportExcel = () => {
          var wb = XLSX.utils.book_new();
          var ws = XLSX.utils.json_to_sheet(listEquipement);
        
          //XLSX.utils.sheet_add_aoa(ws, [['Relevée des sorties']], { origin: -1 });
        
          // Mettre la première ligne en gras
          //XLSX.utils.sheet_set_range_style(ws, { s: { font: { bold: true } }, e: { font: { bold: true } } });
        
          XLSX.utils.book_append_sheet(wb, ws , `Equipements de ${agence.nomAgence}`);
        
          XLSX.writeFile(wb , `Equipements de ${agence.nomAgence}.xlsx`);
        
        }
  
        const [loading, setLoading] = useState(true);
        const [currentImage, setCurrentImage] = useState(0);

        useEffect(() => {
          try {
            request();
            
          } catch (error) {
            console.log(error)
          }

          const timer = setTimeout(() => {
            // Mettez à jour l'état pour arrêter le chargement après 3 secondes
            setLoading(false);
          }, 2000); // 3000 millisecondes = 3 secondes
      
          // Nettoyez le timer lorsque le composant est démonté
          

            const handleResize = () => {
              setWindowWidth(window.innerWidth);
          };
  
          window.addEventListener('resize', handleResize);
        
            return () => {
              clearTimeout(timer);
              window.removeEventListener('resize', handleResize);
          };
          }, []);


  const navigate = useNavigate();

  if(!loading){
    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>
                <Button variant="contained" style={{minWidth: 100, marginRight: 'auto'}} startIcon={<ArrowBackIcon />}   onClick={() => {handleLinkClick(`clients/${client_id}/${compte_id}/agences/${compte_id}`)}} >
                        RETOUR                      
                </Button>
                <Tooltip title="Imprimer">
                    <IconButton onClick={() => handlePrint()} >
                        <PrintIcon style={{color: 'blue'}}/>                    
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en PDF">
                    <IconButton onClick={() => handlePrint()} >
                        <PictureAsPdfIcon  style={{color: 'red'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en EXCEL">
                    <IconButton onClick={() => handleExportExcel()} >
                        <TableChartIcon style={{color: 'green'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en CSV">
                    <IconButton onClick={() => {}} >
                        <DescriptionIcon style={{color: '#2D9596'}} />                     
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copier">
                    <IconButton onClick={() => {}} >
                        <FileCopyIcon style={{color: 'black'}}/>                     
                    </IconButton>
                </Tooltip>                 
            </Stack>
            <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                      marginLeft: windowWidth>700?10:2, 
                      marginRight: windowWidth>700?10:2, 
                      borderRadius: 5, 
                      boxShadow: '0px 0px 5px #0802A3',
                      backgroundColor:'white'}}>
                  <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
                    Liste des equipements de {agence.nomAgence}
                  </Stack>
                  <TextField type="text" 
                          value={searchQuery} 
                          style={{marginBottom: 15, marginRight: 'auto', marginLeft:8}}
                          onChange={handleSearchChange} 
                          placeholder="Rechercher...">
                    </TextField>
                    <div></div>
                  <Stack> 
                    {windowWidth>700?                   
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 600 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#0802A3',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={8}>Equipement de {agence.nomAgence}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>                                           
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro serie</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Garantie</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date garantie</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Nom</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Statut</TableCell>                                                                                      
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Actions</TableCell>                                            
                                    </TableRow>
                                    {listEquipement.length === 0 ? (
                                        <TableRow>
                                          <TableCell colSpan={5} align="center" style={{fontWeight: 600, color: 'red'}}>
                                            Cette agence n'a pas d'équipement.
                                            <br />
                                            Ajoutez-les ! Grace au bouton ajouter ci-dessous.
                                          </TableCell>
                                        </TableRow>
                                      ) : (
                                        data.map((row, index) => (
                                    <TableRow>                                            
                                            <TableCell align="left" style={{fontWeight: 600}} role='button' onClick={() => {navigate(`/reporting/${compte_id}/${row.numeroSerie}/equipement/${compte_id}`)}}>{row.numeroSerie}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600,
                                                color: dateActuelle>=row.dateDG && dateActuelle<=row.dateFG ?'green':'red' }}>
                                                 <Button style={{color:'white',
                                                  backgroundColor: dateActuelle>=row.dateDG && dateActuelle<=row.dateFG ? '#8FD14F':'#DD4A48',
                                                  borderRadius:15,
                                                  fontFamily:'monospace', padding:2, textTransform:'none'}}>
                                                   {dateActuelle>=row.dateDG && dateActuelle<=row.dateFG ?'OUI':'NON'}
                                                   </Button>
                                            </TableCell>
                                            <TableCell align="center" style={{fontWeight: 600,
                                               }}>
                                                <Stack direction={'column'} spacing={1}>
                                                 <Button style={{color:'white',
                                                  backgroundColor:'#16C79A',
                                                  borderRadius:15,
                                                  fontFamily:'monospace', padding:3, textTransform:'none'}}>
                                                   {`Début: ${format(new Date(row.dateDG), 'dd-MM-yyyy')}`}
                                                   </Button>
                                                   <Button style={{color:'white',
                                                  backgroundColor:'#11698E',
                                                  borderRadius:15,
                                                  fontFamily:'monospace', padding:3, textTransform:'none'}}>
                                                   {`Fin: ${format(new Date(row.dateFG), 'dd-MM-yyyy')}`}
                                                   </Button>
                                                   </Stack>
                                            </TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.nomProd}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600 , color: row.statutEquipement=='En service' ? 'green' : 'red'}}>{row.statutEquipement}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>   
                                                  <Stack direction={'column'}
                                                      spacing={{sm: 1}}
                                                      alignItems={'left'}                                                   
                                                  >
                                                    <Stack direction={'row'}
                                                      spacing={{sm: 0}} >    
                                                       <Tooltip title='Modifier la garantie'>
                                                        <IconButton style={{ color: '#C21010' }} onClick={()=>{                                                          
                                                         handleClickOpenDialogGarantie(row.id, row.dateDG,row.dateFG)
                                                        }} >
                                                          <CalendarMonthIcon />
                                                        </IconButton>
                                                      </Tooltip>                                                  
                                                      <Tooltip title='Changer le statut'>
                                                        <IconButton style={{ color: '#0D9276' }} onClick={()=>{                                                          
                                                          changerStatutEquipement(
                                                            row.id,
                                                            row.nom,
                                                            row.statutEquipement
                                                            )
                                                        }} >
                                                          <SyncIcon />
                                                        </IconButton>
                                                      </Tooltip>
                                                     
                                                      <Tooltip title="Historique de l'équipement">
                                                        <IconButton style={{ color: '#0D9276' }} onClick={()=>{   
                                                          navigate(`${row.numeroSerie}/historiques/${compte_id}`)                                                      
                                                        }} >
                                                          <ScheduleIcon />
                                                        </IconButton>
                                                      </Tooltip>
                                                      <Tooltip title="Transfert de l'équipement">
                                                        <IconButton style={{ color: '#0D9276' }} onClick={()=>{   
                                                          handleClickOpenDialogTransfert(row.id, row.nomAgence)                                                    
                                                        }} >
                                                          <TransferIcon />
                                                        </IconButton>
                                                      </Tooltip>  
                                                      <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>                                                                                                       
                                                      </Stack>
                                                    <Stack direction={'row'}
                                                      spacing={{sm: 0}}>                                                                                                       
                                                      </Stack>                                                    
                                                  </Stack>                                             
                                                                                                     
                                            </TableCell>
                                                    </TableRow>
                                                      )))}
                                                </TableBody>
                            </Table>
                    </TableContainer>
                    :
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 150, maxWidth:500 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#0802A3',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={2}>Equipement de {agence.nomAgence}</TableCell>
                                    </TableRow>
                                </TableHead>
                                {data.map((row, index) => (
                                <TableBody>
                                    <TableRow>                                            
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#E1F7F5'}}colSpan={2} >Numéro serie</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{}} role='button'colSpan={2} onClick={() => {navigate(`/reporting/${compte_id}/${row.numeroSerie}/equipement/${compte_id}`)}}>{row.numeroSerie}</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                          <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Nom</TableCell>
                                          <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Statut</TableCell>                                                                                      
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{}}>{row.nomProd}</TableCell>
                                            <TableCell align="center" style={{  color: row.statutEquipement=='En service' ? 'green' : 'red'}}>{row.statutEquipement}</TableCell>                                            
                                    </TableRow>
                                    <TableRow>                                            
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}colSpan={2} >Garantie</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{}} role='button'colSpan={2}>
                                    <Button style={{color:'white',
                                                  backgroundColor: dateActuelle>=row.dateDG && dateActuelle<=row.dateFG ? '#8FD14F':'#DD4A48',
                                                  borderRadius:15,
                                                  fontFamily:'monospace', padding:2, textTransform:'none'}}>
                                                   {dateActuelle>=row.dateDG && dateActuelle<=row.dateFG ?'OUI':'NON'}
                                                   </Button>
                                                   </TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                          <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date début garantie</TableCell>
                                          <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date fin garantie</TableCell>                                                                                      
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" ><Button style={{color:'white',
                                                  backgroundColor:'#16C79A',
                                                  borderRadius:15,
                                                  fontFamily:'monospace', padding:3, textTransform:'none'}}>
                                                   {`${format(new Date(row.dateDG), 'dd-MM-yyyy')}`}
                                                   </Button>
                                            </TableCell>
                                            <TableCell align="center">
                                            <Button style={{color:'white',
                                                  backgroundColor:'#11698E',
                                                  borderRadius:15,
                                                  fontFamily:'monospace', padding:3, textTransform:'none'}}>
                                                   {`${format(new Date(row.dateFG), 'dd-MM-yyyy')}`}
                                                   </Button>
                                                   </TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                            
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}colSpan={2} >Action</TableCell>                                            
                                    </TableRow>
                                        
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600}} colSpan={2}>  
                                                    <Tooltip title='Modifier la garantie'>
                                                        <IconButton style={{ color: '#C21010' }} onClick={()=>{                                                          
                                                         handleClickOpenDialogGarantie(row.id, row.dateDG,row.dateFG)
                                                        }} >
                                                          <CalendarMonthIcon />
                                                        </IconButton>
                                                      </Tooltip>                                                                                                           
                                                      <Tooltip title='Changer le statut'>
                                                        <IconButton style={{ color: '#0D9276' }} onClick={()=>{                                                          
                                                          changerStatutEquipement(
                                                            row.id,
                                                            row.nom,
                                                            row.statutEquipement
                                                            )
                                                        }} >
                                                          <SyncIcon />
                                                        </IconButton>
                                                      </Tooltip>
                                                      <Tooltip title="Historique de l'équipement">
                                                        <IconButton style={{ color: '#0D9276' }} onClick={()=>{   
                                                          navigate(`${row.numeroSerie}/historiques/${compte_id}`)                                                      
                                                        }} >
                                                          <ScheduleIcon />
                                                        </IconButton>
                                                      </Tooltip>
                                                      <Tooltip title="Transfert de l'équipement">
                                                        <IconButton style={{ color: '#0D9276' }} onClick={()=>{   
                                                          handleClickOpenDialogTransfert(row.id, row.nomAgence)                                                    
                                                        }} >
                                                          <TransferIcon />
                                                        </IconButton>
                                                      </Tooltip> 
                                                      <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>                                                                                                                                                                       
                                            </TableCell>
                                                    </TableRow>
                                                    </TableBody>
                                                      ))}
                                                
                            </Table>
                    </TableContainer>
                                                    }                    
                  </Stack>
                  <div class={windowWidth>700?"flex-row":"flex-column"} style={{margin: 10}}>
                        <nav style={{marginRight:'auto'}}> 
                            <ul className='pagination'>
                                <li className='page-item'>
                                  <a href='#' className='page-link'
                                    onClick={prePage}>Préc</a>
                                </li>
                                {
                                  getPageNumbersToShow().map((n,i) =>
                                  (
                                    <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                      <a href='#' className='page-link'
                                      onClick={() => changeCPage(n)}>{n}</a>
                                    </li>
                                  )
                                  )
                                }
                                <li className='page-item'>
                                  <a href='#' className='page-link'
                                    onClick={nextPage}>Suiv</a>
                                </li>
                            </ul>
                        </nav>
                        <div>
                          <h5>Total {listEquipement.length}</h5> 
                        </div>
                </div>
                  <Button variant="contained" style={{margin: 20}} startIcon={<AddIcon />}   onClick={() => handleClickOpenDialog()} >
                    Ajouter                      
            </Button> 
            </Stack>
            <Dialog
                  //fullScreen={fullScreen}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information équipement"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                            <Autocomplete
                              id="combo-box-demo"
                              options={listProduit}
                              getOptionLabel={(option) => option.nomProd}
                              size='medium'
                              style={{minWidth: windowWidth>700?200:100, marginBottom:8}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={produit_id.value}
                              onChange={(event, newValue) => {
                                setproduit_id(newValue ? newValue.id:'');
                                console.log(newValue ? newValue.id:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionnez un équipement"  />
                              )}
                            />
                            <TextField type='text' 
                                label="Statut de l'équipement"
                                value={statutEquipement}
                                select
                                style={{minWidth:windowWidth>700?200:100, marginBottom:8}}
                                onChange={(e)=> {
                                    setstatutEquipement(e.target.value)
                                }}
                            >
                              <MenuItem key={'En service'} value={'En service'}>
                                {'En service'}
                              </MenuItem>
                              <MenuItem key={'Hors service'} value={'Hors service'}>
                                {'Hors service'}
                              </MenuItem>
                            </TextField>

                            <TextField type='text' 
                                label="Numéro de série"
                                value={numeroSerie}
                                style={{minWidth:windowWidth>700?200:100, marginBottom:8}}
                                onChange={(e)=> {
                                    setNumerpSerie(e.target.value)
                                }}
                            >                              
                            </TextField>
                            <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                           <Stack direction={'column'}>
                                <label> Date début garantie</label>
                                    <TextField type='date'                                         
                                        style={{minWidth: windowWidth>700? 230 : 130,maxWidth: windowWidth>700? 230 : 220,marginTop: windowWidth>700 ? 0:5}}
                                        value={dateDG}
                                        onChange={(e)=> {
                                            setDateDG(e.target.value)
                                            console.log(dateDG)
                                        }}
                                    ></TextField>
                            </Stack>   
                            <Stack direction={'column'}>
                                <label> Date fin garantie</label>
                                    <TextField type='date'                                         
                                        style={{minWidth: windowWidth>700? 230 : 130,maxWidth: windowWidth>700? 230 : 220,marginTop: windowWidth>700 ? 0:5}}
                                        value={dateFG}
                                        onChange={(e)=> {
                                            setDateFG(e.target.value)
                                            console.log(dateFG)
                                        }}
                                    ></TextField>
                            </Stack>                                
                          </Stack>
                                                                    
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {ajoutEquipement()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialog} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  //fullScreen={fullScreen}
                  open={openDialogGarantie}
                  onClose={handleCloseDialogGarantie}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Garantie de l'équipement"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                           
                            
                           <Stack direction={'column'}>
                                <label> Date début garantie</label>
                                    <TextField type='date'                                         
                                        style={{minWidth: windowWidth>700? 230 : 130,maxWidth: windowWidth>700? 230 : 220,marginTop: windowWidth>700 ? 0:5}}
                                        value={dateDG}
                                        onChange={(e)=> {
                                            setDateDG(e.target.value)
                                            console.log(dateDG)
                                        }}
                                    ></TextField>
                            </Stack>   
                            <Stack direction={'column'}>
                                <label> Date fin garantie</label>
                                    <TextField type='date'                                         
                                        style={{minWidth: windowWidth>700? 230 : 130,maxWidth: windowWidth>700? 230 : 220,marginTop: windowWidth>700 ? 0:5}}
                                        value={dateFG}
                                        onChange={(e)=> {
                                            setDateFG(e.target.value)
                                            console.log(dateFG)
                                        }}
                                    ></TextField>
                            </Stack>
                                                                    
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {modifierGarantie()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialogGarantie} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  //fullScreen={fullScreen}
                  open={openDialogTransfert}
                  onClose={handleCloseDialogTransfert}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information du transfert"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                            <Autocomplete
                              id="combo-box-demo"
                              options={listAgence}
                              getOptionLabel={(option) => option.nomAgence}
                              size='medium'
                              style={{minWidth:windowWidth>700?200:100, marginBottom:8}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={agence_arrive.value}
                              onChange={(event, newValue) => {
                                setAgence_arrive(newValue ? newValue.id:'');
                                console.log(newValue ? newValue.id:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionnez une agence"  />
                              )}
                            />
                            <TextField type='text' 
                                label="Type du transfert"
                                value={objet_transfert}
                                select
                                style={{minWidth:windowWidth>700?200:100, marginBottom:8}}
                                onChange={(e)=> {
                                    setObjet_transfert(e.target.value)
                                }}
                            >
                              <MenuItem key={'Temporaire'} value={'Temporaire'}>
                                {'Temporaire'}
                              </MenuItem>
                              <MenuItem key={'Définitif'} value={'Définitif'}>
                                {'Définitif'}
                              </MenuItem>
                            </TextField>
                                                                    
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {transfertEquipement()}} style={{fontWeight: 600}}>
                              Transférer 
                            </Button>
                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialogTransfert} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>        
        </div>
    );
    function nextPage(){
      if(firstIndex+10 < listEquipement.length)
      {
        setCurrentPage(currentPage + 1);
        countData=countData+10;
      }
    }
  
    function prePage(){
      if(firstIndex-1>0)
      {
        setCurrentPage(currentPage - 1);
        countData=countData-10;
        console.log(countData)
      }
    }
  
    function changeCPage(id){
      setCurrentPage(id);
      countData = ((id*10)-9)
    }
  }
    else
{
return(
    <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // 100% de la hauteur de la vue
    }}
  >
    <Stack direction={'column'} alignItems={'center'} spacing={1}>
        <CircularProgress
        />
        <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
    </Stack>
  </Box>
);
}
}