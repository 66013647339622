import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import { Autocomplete ,CircularProgress,MenuItem,Box,Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip} from '@mui/material';
import { Navigate, useNavigate,useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import B from '../images/B.png';
import B2 from '../images/B2.png';
import H from '../images/H.png';
import hand from '../images/hand.png';
import laptop from '../images/laptop.png';
import lock from '../images/lock.png';
import T from '../images/T.png';
import techni from '../images/techni.png';
import wallet from '../images/wallet.png';

const loadingImages = [
     B,
     T,
     techni,
     laptop,
     lock,
     hand,
     B2,     
     wallet
    // Ajoutez autant d'images que nécessaire
  ];


  var BACKEND_URL = 'https://polygonBac.bt-support.net/api/';



export default function ConfigurationNotifications() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [listCompteT, setListCompteT] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [titre, settitre] = useState([]);
    const [signataire, setSignataire] = useState([]);
    const [contenue, setcontenue] = useState('');
    const [date_apparition, setdate_apparition] = useState('');
    const [date_disparition, setdate_disparition] = useState('');
    const { compte_id } = useParams();

    const [openDialog, setOpenDialog] = React.useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
      const action = (
        <React.Fragment>
          <Button color="secondary" size="small" onClick={handleClose}>
            UNDO
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
  

    const request = async () => {
      try{
        await axios.get(`${BACKEND_URL}notifications/index`)
        .then(({data}) => {
            setListCompteT(data);
            console.log(listCompteT);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
      } catch (error) {
        console.log(error)
      }
    }

    const handleClickOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setBackendErrorMessage('')
      setOpenDialog(false);
    };

   
    const ajoutNotifications = async (e) => {
      if(titre=='' || contenue=='' || date_apparition=='' || date_disparition=='')
      {
        setBackendErrorMessage('Veuillez remplir tous les champs')
      }
      else{
      await axios.post(`${BACKEND_URL}notifications/create/${compte_id}`, {
                      titre: titre,
                      contenue: contenue,
                      signataire: signataire,
                     date_apparition: date_apparition,
                     date_disparition: date_disparition
                }).then((data) =>{
                  request();
                  console.log(data)
                  setOpenDialog(false);
                })
                .catch(error => {
                  console.error('Erreur de requête :', error);
                  setBackendErrorMessage(error.response.data.error)
      });
    }
    };


   
    const Deleterequest = async (approId) => {
      if (!window.confirm('Voulez-vous vraiment supprimer?')) {
        return;
      }    
        try {
          await axios.delete(`${BACKEND_URL}tresorerie/destroy/${approId}/${compte_id}`);
          console.log(`Compte tresorerie avec l'ID ${approId} supprimé avec succès`);
          // Ajoutez ici la logique supplémentaire après la suppression
          setOpen(true);
          request()
          window.alert('Element supprimer avec succès')
        } catch (error) {
          console.error('Erreur lors de la suppression de l\'approvisionnement', error);
          window.alert(error.response.data.error)
          // Ajoutez ici la gestion des erreurs
        }
    }

    const handleLinkClick = (link) => {
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
    };
    
        const handlePrint = () => {
        /*const printWindow = window.open('', '_blank');
        
        const tableRows = listCompteT.map((row, index) => (
          `<tr>
            <td>${index + 1}</td>
            <td>${row.name}</td>
            <td>${row.email}</td>
            <td>${row.tel}</td>
          </tr>`
        )).join('');
        
        // Utilisez la chaîne HTML générée pour construire le document HTML
        const htmlContent = `
          <html lang="fr">
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Tableau des utilisateurs</title>
              <style>
                body { font-family: Arial, sans-serif; }
                table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
              </style>
            </head>
            <body>
              <h2>Tableau des rôles</h2>
              <table>
                <thead>
                  <tr>
                    <th>Numéro</th>
                    <th>Nom</th>
                    <th>E-mail</th>
                    <th>Téléphone</th>
                  </tr>
                </thead>
                <tbody>
                  ${tableRows}
                </tbody>
              </table>
            </body>
          </html>`;
        
        // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
        printWindow.document.write(htmlContent);
        printWindow.document.close();
        printWindow.print();*/
        };

        useEffect(() => {
          try {
            request();
            
          } catch (error) {
            console.log(error)
          }
            const timer = setTimeout(() => {
              // Mettez à jour l'état pour arrêter le chargement après 3 secondes
              setLoading(false);
            }, 1000); // 3000 millisecondes = 3 secondes
        
            // Nettoyez le timer lorsque le composant est démonté
            const interval = setInterval(() => {
              setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
            }, 100);

            const handleResize = () => {
              setWindowWidth(window.innerWidth);
          };
        
          window.addEventListener('resize', handleResize);

            return () => {
              clearTimeout(timer);
              clearInterval(interval);
              window.removeEventListener('resize', handleResize);
          };
          }, []);

          const [loading, setLoading] = useState(true);
          const [currentImage, setCurrentImage] = useState(0);
  const navigate = useNavigate();

  if( !loading) {
    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            <Nav compte_id={compte_id} />
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>
                <Button variant="contained" style={{minWidth: 100, marginRight: 'auto'}} startIcon={<ArrowBackIcon />}   onClick={() => {handleLinkClick(`configurationSysteme/${compte_id}`)}} >
                        RETOUR                      
                </Button>
                <Tooltip title="Imprimer">
                    <IconButton onClick={() => handlePrint()} >
                        <PrintIcon style={{color: 'blue'}}/>                    
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en PDF">
                    <IconButton onClick={() => handlePrint()} >
                        <PictureAsPdfIcon  style={{color: 'red'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en EXCEL">
                    <IconButton onClick={() => handlePrint()} >
                        <TableChartIcon style={{color: 'green'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en CSV">
                    <IconButton onClick={() => handlePrint()} >
                        <DescriptionIcon style={{color: '#2D9596'}} />                     
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copier">
                    <IconButton onClick={() => handlePrint()} >
                        <FileCopyIcon style={{color: 'black'}}/>                     
                    </IconButton>
                </Tooltip>                 
            </Stack>
            <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                      marginLeft: 2, 
                      marginRight: 2, 
                      borderRadius: 5, 
                      minWidth:windowWidth-150,
                      boxShadow: '0px 0px 5px #B67352',
                      backgroundColor:'white'}}>
                  <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
                    Liste des notifications
                  </Stack>
                  <Stack>
                    {windowWidth>700?
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: windowWidth-200  }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#B67352',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={9}>Notifications</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Titre</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Contenue</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Signataire</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date Apparition</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date Disparition</TableCell>                                           
                                    </TableRow>
                                        {listCompteT.map((row, index) => (
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600}}>{index+1}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600 }}>{row.titre}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.contenue}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.signataire}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.date_apparition}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.date_disparition}</TableCell>                                           
                                           
                                    </TableRow>
                                                      ))}
                                                </TableBody>
                            </Table>
                    </TableContainer>
                    :
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: windowWidth-200  }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#B67352',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={9}>Notifications</TableCell>
                                    </TableRow>
                                </TableHead>
                                {listCompteT.map((row, index) => (
                                <TableBody>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#FED8B1'}} colSpan={2}>Titre</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{fontWeight: 600 }} colSpan={2}>{row.titre}</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2}>Contenue</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{fontWeight: 600}} colSpan={2}>{row.contenue}</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2}>Signataire</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{fontWeight: 600}} colSpan={2}>{row.signataire}</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date Apparition</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date Disparition</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600}}>{row.date_apparition}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{row.date_disparition}</TableCell>                                            
                                    </TableRow>                                                                                        
                                                </TableBody>
                                                 ))}
                            </Table>
                    </TableContainer>}
                  </Stack>
                  <Button variant="contained" style={{margin: 20}} startIcon={<AddIcon />}   onClick={() => handleClickOpenDialog()} >
                    Ajouter                      
            </Button> 
            </Stack>
            <Dialog
                  fullScreen={fullScreen}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information de la notification"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                          
                            <TextField type='text' 
                                label='Titre'
                                value={titre}
                                onChange={(e)=> {
                                    settitre(e.target.value)
                                }}
                            ></TextField>                            
                          <TextField type='text' 
                                label='Contenue'
                                multiline
                                rows={4} // Nombre de lignes visibles par défaut                          
                                value={contenue}
                                onChange={(e)=> {
                                    setcontenue(e.target.value)
                                }}
                                minRows={5}
                            ></TextField>
                            <TextField type='text' 
                                label='Ecrit par'
                                value={signataire}
                                onChange={(e)=> {
                                    setSignataire(e.target.value)
                                }}
                            ></TextField>        
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                          <Stack direction={'column'}>
                             <label> Date apparition</label>
                            <TextField type='date' 
                                label=''
                                value={date_apparition}
                                onChange={(e)=> {
                                    setdate_apparition(e.target.value)
                                }}
                                
                            ></TextField>
                            </Stack>
                            <Stack direction={'column'}>
                            <label> Date disparition</label>
                            <TextField type='date' 
                                label=''
                                value={date_disparition}
                                onChange={(e)=> {
                                    setdate_disparition(e.target.value)
                                }}
                            ></TextField>
                            </Stack>
                            </Stack>
                            
                          
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {ajoutNotifications()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialog} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>   
        </div>
    );
  }
  else
  {
      return(
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // 100% de la hauteur de la vue
          }}
        >
          <Stack direction={'column'} alignItems={'center'} spacing={1}>
              <CircularProgress />
              <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
          </Stack>
        </Box>
      );
  }
}