import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import {Box,CircularProgress ,Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete, MenuItem, Snackbar} from '@mui/material';
import { Navigate, useNavigate,useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SettingsIcon from '@mui/icons-material/Settings';
import axios from 'axios';
import { format } from 'date-fns';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Fuse from 'fuse.js';
import * as XLSX from 'xlsx'
import SyncIcon from '@mui/icons-material/Sync';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import m2agroup from '../images/m2a.png';
import B from '../images/B.png';
import B2 from '../images/B2.png';
import H from '../images/H.png';
import hand from '../images/hand.png';
import laptop from '../images/laptop.png';
import lock from '../images/lock.png';
import T from '../images/T.png';
import techni from '../images/techni.png';
import wallet from '../images/wallet.png';

const loadingImages = [
     B,
     T,
     techni,
     laptop,
     lock,
     hand,
     B2,     
     wallet
    // Ajoutez autant d'images que nécessaire
  ];


  var BACKEND_URL = 'https://polygonBac.bt-support.net/api/';
  var countData=1;



export default function Ticket() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [listTicket, setListTicket] = useState([]);
    const [Ticket, setTicket] = useState([]);
    const [listAgences, setListAgences] = useState([]);   
    const [listUtilisateur, setListUtilisateur] = useState([]);    
    const [agence, setAgence] = useState('');
    const [refIdagence, setrefIdagence] = useState('');
    const [commentaire, setCommentaire] = useState('');
    const [typeIntervention, settypeIntervention] = useState('');
    const [HeureIntervention, setHeureIntervention] = useState('');
    const [dateIntervention, setdateIntervention] = useState('');
    const [statutIntervention, setstatutIntervention] = useState('');
    const [mailTechni, setMailTechni] = useState('');

    const [loading, setLoading] = useState(true);
    
    const [open, setOpen] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [backendErrorMessage, setBackendErrorMessage] = useState('');


    const [openDialogModifier, setOpenDialogModifier] = React.useState(false);
    const [openDialogInterv, setOpenDialogInterv] = React.useState(false);
    const [ticket_id, setTicket_id] = useState('');
    const [technicien_id, settechnicien_id] = useState('');
    const [commentaireInterv, setCommentaireInterv] = useState('');
    const [dateFinIntervention, setdateFinIntervention] = useState('');
    const [dateDebutIntervention, setdateDebutIntervention] = useState('');
    const [focused1, setFocused1] = useState(false);
    const [focused2, setFocused2] = useState(false);
    const [focused3, setFocused3] = useState(false);
    const [focused4, setFocused4] = useState(false);
    const { compte_id } = useParams();
    
    const [currentImage, setCurrentImage] = useState(0);
    let [currentPage, setCurrentPage] =useState(1);    
    const dataPerPage = 10;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const data = listTicket.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(listTicket.length /dataPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    const maxPagesToShow = windowWidth>700?5:3;

    const getPageNumbersToShow = () => {
      const startPage = Math.max(1, currentPage - maxPagesToShow);
      const endPage = Math.min(nPage, currentPage + maxPagesToShow);
    
      return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
    };

    const navigate = useNavigate();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

      const [openSnack, setOpenSnack] = React.useState(false);

      const handleClick = () => {
        setOpenSnack(true);
      };
     
      const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
     
        setOpenSnack(false);
      };

      function SlideTransition(props) {
        return <Slide {...props} direction="up" />;
      }


      const action = (
        <React.Fragment>          
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnack}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
  

    const request = async () => {
      try{
        await axios.get(`${BACKEND_URL}ticket_intervention/${compte_id}`)
        .then(({data}) => {
            setListTicket(data);
            console.log(data);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}agence/${compte_id}`)
        .then(({data}) => {
            setListAgences(data);
            console.log(listAgences);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}utilisateurs/${compte_id}`)
        .then(({data}) => {
            setListUtilisateur(data);
            console.log(listUtilisateur);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
      } catch (error) {
        console.log(error)
      }
    }

    const TicketClos = async (e) => {
      await  axios.get(`${BACKEND_URL}ticket_intervention/ticketClos/${compte_id}`)
      .then(({data}) => {
          setListTicket(data);
          console.log(data);
      }).catch ((error) =>
      {
          setBackendErrorMessage(error.response.data.error);
      });
    }

    const TicketOuvert = async (e) => {
      await axios.get(`${BACKEND_URL}ticket_intervention/ticketOuvert/${compte_id}`)
     .then(({data}) => {
         setListTicket(data);
         console.log(data);
     }).catch ((error) =>
     {
         setBackendErrorMessage(error.response.data.error);
     });
   }

    const handleClickOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setOpenDialog(false);
      setBackendErrorMessage('')
    };
    
    const ajoutTicket = async (e) => {
      if(typeIntervention =='')
      {
        setBackendErrorMessage('Veuillez entrer le type ')
      }
      else if(!refIdagence)
      {
        setBackendErrorMessage('Veuillez choisir une agence')
      }
      else if(statutIntervention== '')
      {
        setBackendErrorMessage('Veuillez entrer le statut du ticket')
      }
      else if(dateIntervention== '')
      {
        setBackendErrorMessage('Veuillez entrer la date d\'appel')
      }
      else if(HeureIntervention== '')
      {
        setBackendErrorMessage('Veuillez entrer l\'heure d\'appel')
      }
      else{
      await axios.post(`${BACKEND_URL}ticket_intervention/create/${compte_id}`, {
                        dateIntervention: dateIntervention,
                        HeureIntervention: HeureIntervention,
                        typeIntervention: typeIntervention,
                        statutIntervention: statutIntervention,
                        commentaire: commentaire,
                        refIdagence: refIdagence
                }).then((data) =>{
                  request();
                  console.log(data)
                  setOpenDialog(false);
                  setBackendErrorMessage('')
                })
                .catch(error => {
                  console.error('Erreur de requête :', error);
                  setBackendErrorMessage(error.response.data.error)
      });
    }
    };

    const handleClickOpenDialogInterv = (ticket_id) => {
        setOpenDialogInterv(true);
        setTicket_id(ticket_id);
      };
    
      const handleCloseDialogInterv = () => {
        setOpenDialogInterv(false);
        setBackendErrorMessage('')
      };
      
      const ajoutInterv = async (ticket_id) => {
        if(technicien_id==null || commentaireInterv=='')
        {
          setBackendErrorMessage('Veuillez remplir tous les champs')
        }
        else if(dateDebutIntervention=='')
        {
          setBackendErrorMessage('Veuillez entrez la date de début')
        }
        else if(dateFinIntervention== '')
        {
          setBackendErrorMessage('Veuillez entrez la date de fin')
        }
        else{
          await axios.post(`${BACKEND_URL}intervention/create/${compte_id}`, {
                             ticket_interv_id: ticket_id,
                             technicien_id: technicien_id,
                             dateDebutIntervention: dateDebutIntervention,
                             dateFinIntervention: dateFinIntervention,
                             commentaire: commentaireInterv
                  }).then((data) =>{
                    console.log(data)
                    window.alert('Intervention enregistré avec succes')
                    if(mailTechni=='' || mailTechni==null)
                    {
                      window.alert(`E-mail incorrect : ${mailTechni}`)
                                  setOpenDialog(false);
                                  setBackendErrorMessage('');                                                                    
                                  setMailTechni('')
                                  settechnicien_id('')
                                  setdateDebutIntervention('')
                                  setdateFinIntervention('')
                                  setCommentaireInterv('')
                                  request();
                    
                    }
                    else
                    {
                        axios.post(`${BACKEND_URL}envoie-mails`, {
                                          email: mailTechni                              
                                  }).then((data1) => {
                                    request();
                                    console.log(data1)
                                    //setOpenDialog(false);
                                    //setBackendErrorMessage('');
                                    
                                    window.alert(`Mail envoyé avec succes à ${mailTechni}`);
                                    setMailTechni('')
                                    setOpenDialogInterv(false);
                                    setBackendErrorMessage('')
                                    navigate(`/interventions/${compte_id}`)
                      
                                  })
                                  .catch(error => {
                                    console.error('Erreur de requête :', error);
                                    window.alert(`Problème d\'envoies de mails à ${mailTechni}`)
                                    setOpenDialog(false);                                                                                                        
                                    setMailTechni('')
                                    settechnicien_id('')
                                    setdateDebutIntervention('')
                                    setdateFinIntervention('')
                                    setCommentaireInterv('')
                                    request();
                                    setBackendErrorMessage(error.response.data.error)
                                });
                      } 
                    

                  })
                  .catch(error => {
                    console.error('Erreur de requête :', error);
                    setBackendErrorMessage(error.response.data.error)
        });
        }        
  
      };

      const modifierTCKStatut = async (tck_id, HeureIntervention, dateIntervention, typeIntervention, commentaire, refIdagence , statut) => {
        if(statut== 'Ouvert')
        {
                  await axios.put(`${BACKEND_URL}ticket_intervention/update/${tck_id}/${compte_id}`, {
                    dateIntervention: dateIntervention,
                    HeureIntervention: HeureIntervention,
                    typeIntervention: typeIntervention,
                    statutIntervention: 'Clos',
                    commentaire: commentaire,
                    refIdagence: refIdagence
                  }).then((data) =>{
                  console.log(data);
                  handleClick();
                  request()
                  })
                  .catch(error => {
                  console.error('Erreur de requête :', error);
                  setBackendErrorMessage(error.response.data.error)
                  });
        }  
        else if(statut=='Clos')   
        {
          await axios.put(`${BACKEND_URL}ticket_intervention/update/${tck_id}/${compte_id}`, {
                    dateIntervention: dateIntervention,
                    HeureIntervention: HeureIntervention,
                    typeIntervention: typeIntervention,
                    statutIntervention: 'Ouvert',
                    commentaire: commentaire,
                    refIdagence: refIdagence
                  }).then((data) =>{
                  console.log(data);
                  handleClick();
                  request()
                  })
                  .catch(error => {
                  console.error('Erreur de requête :', error);
                  setBackendErrorMessage(error.response.data.error)
                  });
        }   
  
      };

      const handleClickOpenDialogModifier = async (ticket_id) => {
        console.log(ticket_id)
        setTicket_id(ticket_id);
        await axios.get(`${BACKEND_URL}ticket_intervention/show/${ticket_id}/${compte_id}`)
        .then(({data}) => {
            setTicket(data);
            console.log(data);
            setrefIdagence(data.refidagence)
            setdateIntervention(format(new Date(data.dateintervention), 'yyyy-MM-dd'))
            console.log(data.HeureIntervention)
            setHeureIntervention(data.HeureIntervention)
            settypeIntervention(data.typeIntervention)
            setstatutIntervention(data.statutIntervention)
            setCommentaire(data.commentaire)
            setOpenDialogModifier(true);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
      };
    
      const handleCloseDialogModifier = () => {
        setOpenDialogModifier(false);
      };

      const charger=()=>{
        
      }


      const modifierTCK = async (ticket_id) => {
        console.log(dateIntervention)
        await axios.put(`${BACKEND_URL}ticket_intervention/update/${ticket_id}/${compte_id}`, {
                                    dateIntervention: dateIntervention,
                                    HeureIntervention: HeureIntervention,
                                    typeIntervention: typeIntervention,
                                    statutIntervention: statutIntervention,
                                    commentaire: commentaire,
                                    refIdagence: refIdagence
                  }).then((data) =>{
                    console.log(data);
                    
                    setOpenDialogModifier(false);
                   request()
                  })
                  .catch(error => {
                    console.error('Erreur de requête :', error);
                    setBackendErrorMessage(error.response.data.error)
        });
      };


    

    const Deleterequest = async (approId) => {
      if (!window.confirm('Voulez-vous vraiment supprimer?')) {
        return;
      }    
        try {
          await axios.delete(`${BACKEND_URL}ticket_intervention/destroy/${approId}/${compte_id}`);
          console.log(`Approvisionnement avec l'ID ${approId} supprimé avec succès`);
          // Ajoutez ici la logique supplémentaire après la suppression
          setOpen(true);
          request();
          window.alert('Element supprimer avec succès')
        } catch (error) {
          console.error('Erreur lors de la suppression de l\'approvisionnement', error);
          window.alert(error.response.data.error)
          // Ajoutez ici la gestion des erreurs
        }
    }

    const handleLinkClick = (link) => {
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
    };

    

const [searchQuery, setSearchQuery] = useState('');
    // Configuration de Fuse.js
const fuseOptions = {
 keys: ['codeTck', 'nomAgence', 'typeIntervention'], // Les champs à rechercher
 includeScore: true,
};

// Initialiser un nouvel objet Fuse avec les options
const fuse = new Fuse(listTicket, fuseOptions);

// Fonction pour gérer le changement de la recherche
const handleSearchChange = (e) => {
 const query = e.target.value;
 setSearchQuery(query);

 // Effectuer la recherche et mettre à jour les résultats
 const results = fuse.search(query);

 if (query==''){
   //setApproData(results.map((result) => result.item));
   request()
 }
 else{
   setListTicket(results.map((result) => result.item));
 }

};


const handleExportExcel = () => {
  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.json_to_sheet(listTicket);

  //XLSX.utils.sheet_add_aoa(ws, [['Relevée des sorties']], { origin: -1 });

  // Mettre la première ligne en gras
  //XLSX.utils.sheet_set_range_style(ws, { s: { font: { bold: true } }, e: { font: { bold: true } } });

  XLSX.utils.book_append_sheet(wb, ws , `Tickets `);

  XLSX.writeFile(wb , `Tickets.xlsx`);

}
    
        const handlePrint = () => {
          const printWindow = window.open('', '_blank');
          
            const tableRows = listTicket.map((row, index) => (
              `<tr>
                <td>${index + 1}</td>
                <td>${row.codeTck}</td> 
                <td>${row.nomAgence}</td> 
                <td>${row.typeIntervention}</td> 
                <td>${row.statutIntervention}</td>
                <td>${row.dateIntervention==null ? '--:--': format(new Date(row.dateIntervention), 'dd-MM-yyyy')}</td>                                         
                <td>${row.HeureIntervention==null ? '--:--': row.HeureIntervention}</td>
                <td>${row.commentaire==null ? '----': row.commentaire}</td>
              </tr>`
            )).join('');
            
            // Utilisez la chaîne HTML générée pour construire le document HTML
            const htmlContent = `
              <html lang="fr">
                <head>
                  <meta charset="UTF-8">
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title>Tableau des tickets d'interventions</title>                
                  <style>
                    body { font-family: Arial, sans-serif; }
                    table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                    th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                    th { background-color: #f2f2f2; }
                    .header {
                      border-bottom: 2px solid #000;
                      margin-bottom: 20px;
                      padding-bottom: 10px;
                      }
                      .header img {
                          width: 200px;
                          height: auto;
                          float: left;
                          margin-right: 20px;
                      }
                      .header h1,
                      .header p {
                          margin: 0;
                          padding: 0;
                      }
                      /* Aligne les éléments pour l'impression */
                      .header h1 {
                          font-size: 24px;
                          line-height: 1.2;
                          color: red
                      }
                      .header p {
                          font-size: 14px;
                          line-height: 1.4;
                      }
                  </style>
                </head>
                <body>
                <div class="printable header">
                    <img src=${m2agroup} alt="Logo">                    
                    <h1>POLYGON BIO SERVICES SARL</h1>
                    <p>09 BP 969 Ouagadougou 09</p>
                    <p>Bureau     : (+226) 25 46 29 21</p>
                    <p>Portable 1 : (+226) 70 80 82 08</p>
                    <p>Portable 2 : (+226) 79 67 11 11</p>
                    <p>Whatsapp/ WeChat : (+226) 76 59 71 28</p>
                    <p>E-mail : polygonbioservice@yahoo.fr</p>
                    <p>www.polygonbioservices.com</p>
                    <p>Situé au quartier Sinyiri côté Ouest de la ZAD (SIAO)</p>
                    <p>Siège social 01 BP 6727 OUAGA 01</p>                    
                </div>
                  <h2>Tableau des tickets d'interventions</h2>
                  <table>
                    <thead>
                      <tr>
                      <th>Numéro</th>
                      <th>Code</th>
                      <th>Agence</th> 
                      <th>Type ticket</th>                                                                                                                                 
                      <th>Statut</th>
                      <th>Date d'appel</th>
                      <th>Heure d'appel</th>
                      <th>Commentaire</th>   
                       </tr>
                    </thead>
                    <tbody>
                      ${tableRows}
                    </tbody>
                  </table>
                </body>
              </html>`;
            
            // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
            printWindow.document.write(htmlContent);
            printWindow.document.close();
            printWindow.print();
        };

        useEffect(() => {
          try {
            request();
            
          } catch (error) {
            console.log(error)
          }
            const timer = setTimeout(() => {
                // Mettez à jour l'état pour arrêter le chargement après 3 secondes
                setLoading(false);
              }, 3000); // 3000 millisecondes = 3 secondes
          
              // Nettoyez le timer lorsque le composant est démonté
              const interval = setInterval(() => {
                setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
              }, 300);

              const handleResize = () => {
                setWindowWidth(window.innerWidth);
            };
    
            window.addEventListener('resize', handleResize);

              return () => {
                clearTimeout(timer);
                clearInterval(interval);
                window.removeEventListener('resize', handleResize);
            };
            
              
          }, []);


    if(!loading) {

    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            <Nav compte_id={compte_id} />
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>
                <div style={{marginRight: 'auto'}}><h3>Gestion des tickets</h3></div>
                <Tooltip title="Imprimer">
                    <IconButton onClick={() => handlePrint()} >
                        <PrintIcon style={{color: 'blue'}}/>                    
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en PDF">
                    <IconButton onClick={() => {}} >
                        <PictureAsPdfIcon  style={{color: 'red'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en EXCEL">
                    <IconButton onClick={() => handleExportExcel()} >
                        <TableChartIcon style={{color: 'green'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en CSV">
                    <IconButton onClick={() => {}} >
                        <DescriptionIcon style={{color: '#2D9596'}} />                     
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copier">
                    <IconButton onClick={() => {}} >
                        <FileCopyIcon style={{color: 'black'}}/>                     
                    </IconButton>
                </Tooltip>                 
            </Stack>
            <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                      marginLeft: windowWidth>700?5:2, 
                      marginRight: windowWidth>700?5:2, 
                      borderRadius: 5, 
                      minWidth: windowWidth>700? 750:350,
                      boxShadow: '0px 0px 5px #711DB0',
                      backgroundColor:'white'}}>
                  <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
                    Liste des tickets
                  </Stack>
                  <Stack direction={windowWidth>700?'row':'column'}
          spacing={2}
        alignItems='center'
    >
                  <TextField type="text" 
                          value={searchQuery} 
                          style={{marginBottom: 15, 
                            marginRight:'auto',
                           marginLeft:8}}
                          onChange={handleSearchChange} 
                          placeholder="Rechercher...">
                    </TextField>
                    
                    
                <div style={{marginBottom: 10, //marginRight: 3
                        }}>
                <Button variant='contained' onClick={() => {TicketClos()}}>Tickets cloturer</Button>
                        </div>
                        
                <div style={{marginBottom: 10, //marginRight: 3
                }}>
                <Button variant='contained' onClick={() => {TicketOuvert()}}>Tickets ouvert</Button>
                </div>
                <div style={{marginBottom: 10, alignContent: 'center'}}>
                
                            <Tooltip title="Reinitialiser les filtres" >
                              <IconButton style={{ color: '#0D9276'}} onClick={()=>{   
                                    request()
                              }} >
                                <SyncIcon />
                              </IconButton>
                            </Tooltip>  
                </div>
                </Stack>

                  <Stack alignItems='center'>                
               { windowWidth > 700 ?
                    <TableContainer component={Paper} >
                            <Table sx={{ minWidth: 600, maxWidth: (windowWidth-100) }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#711DB0',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={9}>Ticket</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Code</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Agence</TableCell>                                            
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Type ticket</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Statut</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date d'appel</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Heure d'appel</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Commentaire</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Action</TableCell>                                            
                                    </TableRow>
                                        {data.map((row, index) => (
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600}}>{countData+index}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.codeTck}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.nomAgence}</TableCell>                                            
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.typeIntervention}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, color: row.statutIntervention=='Ouvert'? 'red' : 'green'}}>{row.statutIntervention}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{format(new Date(row.dateIntervention), 'yyyy-MM-dd')==null ? '--:--': format(new Date(row.dateIntervention), 'dd-MM-yyyy')}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.HeureIntervention==null ? '--:--': row.HeureIntervention}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.commentaire==null ? '----': row.commentaire}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>                                                
                                                    <Tooltip title='Modifier'>
                                                        <IconButton style={{ color:  '#40A2E3' }}  onClick={()=>{
                                                          console.log(row.id)
                                                            handleClickOpenDialogModifier(row.id)                                                            
                                                        }} >
                                                          <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>                          
                                                    {row.statutIntervention=='Ouvert'?<Tooltip title="Entamer l'intervention">
                                                        <IconButton style={{ color: '#0D9276' }} onClick={()=>{
                                                            handleClickOpenDialogInterv(row.id)
                                                        }} >
                                                          <SyncIcon />
                                                        </IconButton>
                                                    </Tooltip>:null}
                                                    <Tooltip title= {row.statutIntervention== 'Ouvert' ?  'Cloturer le ticket' : 'Décloturer le ticket'}>
                                                        <IconButton style={{ color: row.statutIntervention== 'Ouvert' ?  '#40A2E3' : 'black'}} onClick={()=>{
                                                            
                                                            console.log(row.refidagence)
                                                            modifierTCKStatut(
                                                                row.id , 
                                                                row.HeureIntervention , 
                                                                row.dateIntervention , 
                                                                row.typeIntervention , 
                                                                row.commentaire , 
                                                                row.refidagence,
                                                                row.statutIntervention)
                                                            }} >
                                                              {row.statutIntervention== 'Ouvert' ? <CheckCircleIcon /> : <CancelIcon />}
                                                          
                                                        </IconButton>
                                                    </Tooltip>                         
                                                    {row.statutIntervention=='Ouvert'?<Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>:null}                                               
                                            </TableCell>
                                                    </TableRow>
                                                      ))}
                                                </TableBody>
                            </Table>
                    </TableContainer>
                    :
                    <TableContainer component={Paper} sx={{ minWidth: 150, maxWidth: 300}}>
                            <Table  aria-label="simple table">
                                <TableHead style={{backgroundColor: '#711DB0',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={2}>Tickets</TableCell>
                                    </TableRow>
                                </TableHead>
                                {data.map((row, index) => (
                                <TableBody>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#E3FEF7'}} colSpan={2} >Code</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{}} colSpan={2} >{row.codeTck}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Agence</TableCell>                                            
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Type ticket</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{}}>{row.nomAgence}</TableCell>                                            
                                            <TableCell align="center" style={{}}>{row.typeIntervention}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date d'appel</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Heure d'appel</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{}}>{format(new Date(row.dateIntervention), 'yyyy-MM-dd')==null ? '--:--': format(new Date(row.dateIntervention), 'dd-MM-yyyy')}</TableCell>
                                            <TableCell align="center" style={{}}>{row.HeureIntervention==null ? '--:--': row.HeureIntervention}</TableCell>                                            
                                    </TableRow>
                                    <TableRow> 
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Statut</TableCell>                                            
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Commentaire</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{ color: row.statutIntervention=='Ouvert'? 'red' : 'green'}}>{row.statutIntervention}</TableCell>
                                            <TableCell align="center" style={{}}>{row.commentaire==null ? '----': row.commentaire}</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2}>Action</TableCell>                                            
                                    </TableRow>                                                                           
                                    <TableRow>                                                                                      
                                            <TableCell align="center" style={{fontWeight: 600}} colSpan={2} >                                                
                                                    <Tooltip title='Modifier'>
                                                        <IconButton style={{ color:  '#40A2E3' }}  onClick={()=>{
                                                          console.log(row.id)
                                                            handleClickOpenDialogModifier(row.id)                                                            
                                                        }} >
                                                          <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>                          
                                                    {row.statutIntervention=='Ouvert'?<Tooltip title="Entamer l'intervention">
                                                        <IconButton style={{ color: '#0D9276' }} onClick={()=>{
                                                            handleClickOpenDialogInterv(row.id)
                                                        }} >
                                                          <SyncIcon />
                                                        </IconButton>
                                                    </Tooltip>:null}
                                                    <Tooltip title= {row.statutIntervention== 'Ouvert' ?  'Cloturer le ticket' : 'Décloturer le ticket'}>
                                                        <IconButton style={{ color: row.statutIntervention== 'Ouvert' ?  '#40A2E3' : 'black'}} onClick={()=>{
                                                            
                                                            console.log(row.refidagence)
                                                            modifierTCKStatut(
                                                                row.id , 
                                                                row.HeureIntervention , 
                                                                row.dateIntervention , 
                                                                row.typeIntervention , 
                                                                row.commentaire , 
                                                                row.refidagence,
                                                                row.statutIntervention)
                                                            }} >
                                                              {row.statutIntervention== 'Ouvert' ? <CheckCircleIcon /> : <CancelIcon />}
                                                          
                                                        </IconButton>
                                                    </Tooltip>                         
                                                    {row.statutIntervention=='Ouvert'?<Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>:null}                                             
                                            </TableCell>
                                                    </TableRow>
                                                    </TableBody>
                                                      ))}
                                                
                            </Table>
                    </TableContainer>
                    }
                    <div class={windowWidth>700?"flex-row":"flex-column"} style={{margin: 10}}>
                        <nav > 
                            <ul className='pagination'>
                                <li className='page-item'>
                                  <a href='#' className='page-link'
                                    onClick={prePage}>Préc</a>
                                </li>
                                {
                                  getPageNumbersToShow().map((n,i) =>
                                  (
                                    <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                      <a href='#' className='page-link'
                                      onClick={() => changeCPage(n)}>{n}</a>
                                    </li>
                                  )
                                  )
                                }
                                <li className='page-item'>
                                  <a href='#' className='page-link'
                                    onClick={nextPage}>Suiv</a>
                                </li>
                            </ul>
                        </nav>
                        <div>
                          <h5>Total : {listTicket.length}</h5> 
                        </div>
                </div>
                  </Stack>
                  <Button variant="contained" style={{margin: 20}} startIcon={<AddIcon />}   onClick={() => {handleClickOpenDialog()}} >
                    Ajouter                      
            </Button> 
            </Stack>
            <Dialog
                  //fullScreen={fullScreen}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information du ticket"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                         {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>} 
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label='Type du ticket'
                                value={typeIntervention}
                                style={{minWidth: windowWidth>700? 230 : 130,maxWidth: windowWidth>700? 230 : 220,marginTop: windowWidth>700 ? 0:5}}
                                select
                                onChange={(e)=> {
                                    settypeIntervention(e.target.value)
                                }}
                            >
                                <MenuItem key={'Curatif'} value={'Curatif'}>
                                {'Curatif'}
                              </MenuItem>
                              <MenuItem key={'Préventif'} value={'Préventif'}>
                                {'Préventif'}
                              </MenuItem>
                              <MenuItem key={'Installation'} value={'Installation'}>
                                {'Installation'}
                              </MenuItem>
                              <MenuItem key={'Mise à disposition'} value={'Mise à disposition'}>
                                {'Mise à disposition'}
                              </MenuItem>
                            </TextField>
                            <Autocomplete
                              id="combo-box-demo"
                              options={listAgences}
                              getOptionLabel={(option) => `${option.nomAgence} - ${option.nomClient}`}
                              size='medium'
                              style={{minWidth: windowWidth>700? 230 : 130,maxWidth: windowWidth>700? 230 : 220,marginTop: windowWidth>700 ? 0:5}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={refIdagence.value}
                             // defaultValue={-1}
                              onChange={(event, newValue) => {
                                setrefIdagence(newValue ? newValue.id:'');
                                console.log(newValue ? newValue.id:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionnez une agence"  />
                              )}
                            />
                          </Stack>
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label='Statut du  ticket'
                                value={statutIntervention}
                                style={{minWidth: windowWidth>700? 230 : 130,maxWidth: windowWidth>700? 230 : 220,marginTop: windowWidth>700 ? 0:5}}
                                select
                                onChange={(e)=> {
                                  setstatutIntervention(e.target.value)
                                }}
                            >
                                <MenuItem key={'Ouvert'} value={'Ouvert'}>
                                {'Ouvert'}
                              </MenuItem>
                              <MenuItem key={'Clos'} value={'Clos'}>
                                {'Clos'}
                              </MenuItem>
                            </TextField>
                            <TextField type='text' 
                                    label="Commentaire"
                                    value={commentaire}
                                    rows={2}
                                    style={{minWidth: windowWidth>700? 230 : 130,maxWidth: windowWidth>700? 230 : 220,marginTop: windowWidth>700 ? 0:5}}                                    
                                    onChange={(e)=> {
                                        setCommentaire(e.target.value)
                                    }}
                                ></TextField>                                                        
                          </Stack>
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <Stack direction={'column'}>
                                <label>Heure d'appel</label>
                                <TextField type='time'                                     
                                    value={HeureIntervention}
                                    style={{minWidth: windowWidth>700? 230 : 130,maxWidth: windowWidth>700? 230 : 220,marginTop: windowWidth>700 ? 0:5}}
                                    onChange={(e)=> {
                                        setHeureIntervention(e.target.value)
                                        
                                    }}
                                ></TextField>                                   
                            </Stack>
                            <Stack direction={'column'}>
                                <label> Date d'appel</label>
                                    <TextField type='date'                                         
                                        style={{minWidth: windowWidth>700? 230 : 130,maxWidth: windowWidth>700? 230 : 220,marginTop: windowWidth>700 ? 0:5}}
                                        value={dateIntervention}
                                        onChange={(e)=> {
                                            setdateIntervention(e.target.value)
                                            console.log(HeureIntervention)
                                        }}
                                    ></TextField>
                            </Stack>                                
                          </Stack>
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {ajoutTicket()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialog} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  //fullScreen={fullScreen}
                  open={openDialogInterv}
                  onClose={handleCloseDialogInterv}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information de l'intervention"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >      
                        {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                        <Stack direction={windowWidth>700?'row':'column'}
                        spacing={{sm: 2}}>
                                 <Autocomplete
                                   id="combo-box-demo"
                                   options={listUtilisateur}
                                   getOptionLabel={(option) => option.name}
                                   size='medium'
                                   style={{minWidth: windowWidth>700? 475 : 130,maxWidth: windowWidth>700? 230 : 220,marginTop: windowWidth>700 ? 0:5}}
                                   /*style={{width: width<800 ?  250 :(width*30)/100,
                                   backgroundColor: '#ffffff'}}*/
                                   value={technicien_id.value}
                                   onChange={(event, newValue) => {
                                     settechnicien_id(newValue ? newValue.id:'');
                                     console.log(newValue ? newValue.id:''); 
                                     setMailTechni(newValue? newValue.email:"")
                                     console.log(newValue? newValue.email:'')
                                     // Nouvelle valeur sélectionnée

                                     //console.log(selectedFournisseur)
                                   }}
                                   renderInput={(params) => (
                                     <TextField {...params} label="Sélectionnez un technicien"  />
                                   )}
                                 />
                                                                 
                            </Stack>
                            <Stack direction={windowWidth>700?'row':'column'}
                                spacing={{sm: 2}}>
                                    <Stack direction={'column'}>
                                        <label> Date de début</label>
                                        <TextField type='date'                                         
                                            style={{minWidth: windowWidth>700? 230 : 130,
                                              maxWidth: windowWidth>700? 230 : 220,
                                              marginTop: windowWidth>700 ? 0:5}}
                                            value={dateDebutIntervention}
                                            onChange={(e)=> {
                                                setdateDebutIntervention(e.target.value)
                                            }}
                                        ></TextField>
                                    </Stack>
                                    <Stack direction={'column'}>
                                            <label> Date de fin</label>
                                        <TextField type='date'                                         
                                            style={{minWidth: windowWidth>700? 230 : 130,
                                              maxWidth: windowWidth>700? 230 : 220,
                                              marginTop: windowWidth>700 ? 0:5,
                                             }}
                                            value={dateFinIntervention}
                                            onChange={(e)=> {
                                                setdateFinIntervention(e.target.value)
                                            }}
                                        ></TextField>
                                    </Stack>
                                          </Stack>
                            <Stack direction={windowWidth>700?'row':'column'}
                                spacing={{sm: 2}}
                                >
                                <TextField type='text' 
                                     label="Commentaire"
                                     value={commentaireInterv}
                                     style={{minWidth: windowWidth>700? 475 : 130,
                                      maxWidth: windowWidth>700? 230 : 220,
                                      marginTop: windowWidth>700 ? 0:5, 
                                      marginTop: windowWidth>700 ? 0:5,
                                      marginBottom: windowWidth>700 ? 0:5}}
                                     multiline
                                     rows={3}
                                     defaultValue="Default Value"
                                     onChange={(e)=> {
                                         setCommentaireInterv(e.target.value)
                                     }}
                                ></TextField>
                            </Stack>                                                                                                                                                      
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {ajoutInterv(ticket_id)}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialogInterv} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>

                <Snackbar
                  open={openSnack}
                  autoHideDuration={6000}
                  //TransitionComponent={SlideTransition}
                  severity="success"
                  onClose={handleCloseSnack}
                  //message=""
                  action={action}
                >
                     <Alert
                       onClose={handleClose}
                       //severity="success"
                       variant="filled"
                       sx={{ width: '100%', backgroundColor: '#65B741' }}
                     >
                       Le ticket a été cloturer
                     </Alert>
                </Snackbar>

                <Dialog
                  //fullScreen={fullScreen}
                  open={openDialogModifier}                
                  onClose={handleCloseDialogModifier}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Modification du ticket"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label={"Type d'intervention"}
                                value={typeIntervention}
                                style={{minWidth: windowWidth>700? 230 : 130,
                                  maxWidth: windowWidth>700? 230 : 220
                                  ,marginTop: windowWidth>700 ? 0:5}}
                                select
                                onChange={(e)=> {
                                    settypeIntervention(e.target.value)
                                }}
                            >
                                <MenuItem key={'Curatif'} value={'Curatif'}>
                                {'Curatif'}
                              </MenuItem>
                              <MenuItem key={'Préventif'} value={'Préventif'}>
                                {'Préventif'}
                              </MenuItem>
                              <MenuItem key={'Installation'} value={'Installation'}>
                                {'Installation'}
                              </MenuItem>
                            </TextField>
                            <Autocomplete
                              id="combo-box-demo"
                              options={listAgences}
                              getOptionLabel={(option) => `${option.nomAgence} - ${option.nomClient}`}
                              size='medium'
                              style={{minWidth: windowWidth>700? 230 : 130,
                                maxWidth: windowWidth>700? 230 : 220
                                ,marginTop: windowWidth>700 ? 0:5}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={refIdagence.value}
                              onChange={(event, newValue) => {
                                setrefIdagence(newValue ? newValue.id:'');
                                console.log(newValue ? newValue.id:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label={focused1 ? "Sélectionnez une agence" : `${Ticket.nomAgence} - ${Ticket.nomClient}`}
                                onFocus={() => setFocused1(true)}
                                onBlur={() => setFocused1(false)}
                                  />
                              )}
                            />
                          </Stack>
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label={'Statut du ticket'}
                                                               
                               
                                value={statutIntervention}
                                style={{minWidth: windowWidth>700? 230 : 130,
                                  maxWidth: windowWidth>700? 230 : 220
                                  ,marginTop: windowWidth>700 ? 0:5}}
                                select
                                onChange={(e)=> {
                                  setstatutIntervention(e.target.value)
                                }}
                                on
                            >
                                <MenuItem key={'Ouvert'} value={'Ouvert'}>
                                {'Ouvert'}
                              </MenuItem>
                              <MenuItem key={'Clos'} value={'Clos'}>
                                {'Clos'}
                              </MenuItem>                              
                            </TextField>
                            <TextField type='text' 
                                    label={"Commentaire"}
                                    value={commentaire}
                                    style={{minWidth: windowWidth>700? 230 : 130,
                                      maxWidth: windowWidth>700? 230 : 220
                                      ,marginTop: windowWidth>700 ? 0:5}}  
                                    //value={}                                  
                                    onChange={(e)=> {
                                        setCommentaire(e.target.value)
                                    }}
                                ></TextField>                                                        
                          </Stack>
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <Stack direction={'column'}>
                                <label> Date d'appel</label>
                                    <TextField type='date'    
                                        defaultValue={dateIntervention}                                 
                                        style={{minWidth: windowWidth>700? 230 : 130,
                                          maxWidth: windowWidth>700? 230 : 220,}}
                                        //value={dateIntervention}
                                        onChange={(e)=> {
                                            setdateIntervention(e.target.value)
                                        }}
                                    ></TextField>
                            </Stack>
                            <Stack direction={'column'}>
                                <label>Heure d'appel</label>
                                <TextField type='time' 
                                    defaultValue={HeureIntervention}                               
                                    //value={HeureIntervention}
                                    style={{minWidth: windowWidth>700? 230 : 130,
                                      maxWidth: windowWidth>700? 230 : 220,}}
                                    onChange={(e)=> {
                                        setHeureIntervention(e.target.value)
                                        console.log(HeureIntervention)
                                    }}
                                ></TextField>                                   
                            </Stack>
                                                            
                          </Stack>
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {
                                modifierTCK(ticket_id)
                                }} 
                                style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                            {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialogModifier} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>      
        </div>
    );

    function nextPage(){
      if(firstIndex+10 < listTicket.length)
      {
        setCurrentPage(currentPage + 1);
        countData=countData+10;
      }
    }
  
    function prePage(){
      if(firstIndex-1>0)
      {
        setCurrentPage(currentPage - 1);
        countData=countData-10;
        console.log(countData)
      }
    }
  
    function changeCPage(id){
      setCurrentPage(id);
      countData = ((id*10)-9)
    }

    }
    else
    {
        return(
            <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh', // 100% de la hauteur de la vue
            }}
          >
            <Stack direction={'column'} alignItems={'center'} spacing={1}>
                <CircularProgress />
                <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
            </Stack>
          </Box>
        );
    }
}